import * as client from "../framework/client"

export const get = () => {
  return client.get("/system_settings")
}

export const update = command_dto => {
  return client.put("/system_settings", command_dto)
}

export const getQuickbooksToken = (code, realmId, callbackUrl, clientId, clientSecret) => {
  return client.get(`/quickbooks/get_token?code=${code}&realm_id=${realmId}&callback_url=${callbackUrl}&client_id=${clientId}&client_secret=${clientSecret}`)
}

export const testQuickbooksIntegration = () => {
  return client.get("/quickbooks/test_integration")
}

export const flushRedis = () => {
  return client.get("/system/redis_flush")
}

export const autoUpdateRedisCounters = () => {
  return client.post("/system/auto_update_redis_counters")
}

export const manualUpdateRedisCounters = (command_dto) => {
  return client.post("/system/manual_update_redis_counters", command_dto)
}

export const testCloudTasks = () => {
  return client.post("/cloud_task/run_task")
}

export const getMaintenanceStatus = () => {
  return client.get("/system/maintenance_status")
}
